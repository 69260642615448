(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("_"), require("mobxReact"), require("antd"));
	else if(typeof define === 'function' && define.amd)
		define("TreeSelectCamera", ["React", "_", "mobxReact", "antd"], factory);
	else if(typeof exports === 'object')
		exports["TreeSelectCamera"] = factory(require("React"), require("_"), require("mobxReact"), require("antd"));
	else
		root["TreeSelectCamera"] = factory(root["React"], root["_"], root["mobxReact"], root["antd"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 